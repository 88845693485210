import { BaseServices, HttpMethod } from '@services/base'
import { MarginQueryInss } from '@store/proposta/leilaoInss/types'

export default (
  tokenApi: string,
  auctionId: string,
  newSimulatorApi: boolean,
  loanType: string,
) => {
  const pathname =
    loanType === 'CTPS'
      ? process.env.REACT_APP_POST_FINISH_CTPS
      : process.env.REACT_APP_POST_MARGIN_QUERY_INSS

  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request<MarginQueryInss>({
    pathname,
    method,
    host,
    headers: {
      authorization: `Bearer ${tokenApi}`,
      auctionId,
    },
  })

  return response
}
