import { ResumoApi } from '@store/assinatura/resumo/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (proposalCode: string, tokenApi: string) => {
  const pathname = `${process.env.REACT_APP_DETALHES_COMPLETOS_PROPOSTA_PORTAL}/${proposalCode}/full-detail`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {}

  headers.Authorization = `Bearer ${tokenApi}`

  const response = BaseServices.request<ResumoApi>({
    pathname,
    method,
    host,
    headers,
  })
  return response
}
