import { BaseServices, HttpMethod } from '@services/base'

export default (
  tokenApi: string,
  auctionId: string,
  proposalCode: string,
  newSimulatorApi: boolean,
) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_POST_FINALIZAR_PENDENCIA_LEILAO_INSS
    : process.env.REACT_APP_POST_FINALIZAR_PENDENCIA_LEILAO_INSS

  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    headers: {
      authorization: `Bearer ${tokenApi}`,
    },
    data: {
      proposalCode,
      auctionId,
    },
  })

  return response
}
