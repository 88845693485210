import getEstados from './getEstados'
import getOrgaosExpedidores from './getOrgaosExpedidores'
import postDadosResumo from './postDadosResumo'
import FilesService from './FilesService'
import GeneratePdf from './GeneratePdf'
import postCaptureUploadLink from './postCaptureUploadLink'
import getCidades from './getCidades'
import getEscolaridades from './getEscolaridades'
import getProfissoes from './getProfissoes'
import getValidaCliente from './getValidaCliente'
import postCaptureDocuments from './postCaptureDocuments'
import postProposta from './postProposta'
import getCaptura from './getCaptura'
import postDocumentosAssinatura from './postDocumentosAssinatura'
import postAtualizarProposta from './postAtualizarProposta'
import getEndereco from './getEndereco'
import getBancos from './getBancos'
import postSalvarProposta from './postSalvarProposta'
import getDadosBancariosInss from './getDadosBancariosInss'
import postInfoValida from './postInfoValida'
import postPropostaGerar from './postPropostaGerar'
import postAssinarDocumentos from './postAssinarDocumentos'
import postPropostaFinalizar from './postPropostaFinalizar'
import postEditarLead from './postEditarLead'
import getValidaIndicacao from './getValidaIndicacao'
import postPropostaIndicacao from './postPropostaIndicacao'
import postTrackNewRelic from './postTrackNewRelic'
import getValidaToken from './getValidaToken'
import postEnviaToken from './postEnviaToken'
import postValidaSenhaExercito from './postValidaSenhaExercito'
import postRealizarAverbacaoSiape from './postRealizarAverbacaoSiape'
import postDocumentoAssincrono from './postDocumentoAssincrono'
import putAtualizarStatusAssinatura from './putAtualizarStatusAssinatura'
import postLivenessBiometria from './postLivenessBiometria'
import postDocumentoLiveness from './postDocumentoLiveness'
import postAutorizacaoISafe from './postAutorizacaoISafe'
import postRiscoEndereco from './postRiscoEndereco'
import getDadosBiometria from './getDadosBiometria'
import postValidateBiometrics from './postValidateBiometrics'
import postSendFileToS3 from './postSendFileToS3'
import getPropostaLeilaoInss from './getPropostaLeilaoInss'
import postMarginQueryInss from './postMarginQueryInss'
import postAtualizarEnderecoLeilaoInss from './postAtualizarEnderecoLeilaoInss'
import postAtualizarDadosBancariosLeilaoInss from './postAtualizarDadosBancariosLeilaoInss'
import postUploadDocLeilaoInss from './postUploadDocLeilaoInss'
import getLastLiveness from './getLastLiveness'
import postFinalizarPendenciaLeilaoInss from './postFinalizarPendenciaLeilaoInss'
import getDocumentosAssinatura from './getDocumentosAssinatura'
import getDadosResumo from './getDadosResumo'

export default {
  getEstados,
  getOrgaosExpedidores,
  postDadosResumo,
  FilesService,
  GeneratePdf,
  postCaptureUploadLink,
  getCidades,
  getEscolaridades,
  getProfissoes,
  getValidaCliente,
  postCaptureDocuments,
  postProposta,
  getCaptura,
  postDocumentosAssinatura,
  postAtualizarProposta,
  getEndereco,
  getBancos,
  postSalvarProposta,
  getDadosBancariosInss,
  postInfoValida,
  postPropostaGerar,
  postAssinarDocumentos,
  postPropostaFinalizar,
  postEditarLead,
  getValidaIndicacao,
  postPropostaIndicacao,
  postTrackNewRelic,
  getValidaToken,
  postEnviaToken,
  postValidaSenhaExercito,
  postRealizarAverbacaoSiape,
  postDocumentoAssincrono,
  putAtualizarStatusAssinatura,
  postLivenessBiometria,
  postDocumentoLiveness,
  postAutorizacaoISafe,
  postRiscoEndereco,
  getDadosBiometria,
  postValidateBiometrics,
  postSendFileToS3,
  getPropostaLeilaoInss,
  postMarginQueryInss,
  postAtualizarEnderecoLeilaoInss,
  postAtualizarDadosBancariosLeilaoInss,
  postUploadDocLeilaoInss,
  getLastLiveness,
  postFinalizarPendenciaLeilaoInss,
  getDocumentosAssinatura,
  getDadosResumo,
}
