export const TiposOrgaos = [
  { value: 'tipo 1', label: 'tipo 1' },
  { value: 'tipo 2', label: 'tipo 2' },
]

export enum TipoDocumento {
  RG = 'rg',
  CNH = 'cnh',
  CARTEIRA_FUNCIONAL = 'rne',
  CONTRACHEQUE = 'contracheque',
}

export enum ErrorCodes {
  // Captura
  C0_001_ORGAOS = 'CO.001', // src/store/captura/documentos/sagas/getOrgaos.ts
  C0_002_S3ENVIO = 'CO.002', // src/store/captura/fotoDocumentos/sagas/sendToS3PDF.ts
  C0_003_S3ENVIO_VIDEO_FRAME = 'CO.003', // src/store/captura/videoSelfie/sagas/sendToS3Video.ts

  // GlobalAPI
  G0_001_ESTADOS = 'GO.001', // src/store/globalApi/sagas/getEstados.ts
  G0_002_CIDADES = 'GO.002', // src/store/globalApi/sagas/getCidades.ts

  // Proposta
  P0_001_ESCOLARIDADES = 'PO.001', // src/store/proposta/escolaridade/sagas/getEscolaridades.ts
  P0_002_PROFISSOES = 'PO.002', // src/store/proposta/escolaridade/sagas/getProfissoes.ts
  P0_003_ENDERECO = 'PO.003', // src/store/proposta/endereco/sagas/getEndereco.ts
  P0_004_CRIAR = 'PO.004', // src/store/proposta/criacaoProposta/sagas/postCriarProposta.ts
  P0_005_DADOS_BANCARIOS = 'PO.005', // src/store/proposta/dadosBancarios/sagas/getBancos.ts
  P0_006_PROPOSTA = 'PO.006', // src/store/proposta/dadosBancarios/sagas/postSalvarPropostaSaga.ts
  P0_007_DADOS_BANCARIOS_INSS = 'PO.007', // src/store/proposta/dadosBancarios/sagas/getDadosBancariosInssSaga.ts
  P0_008_VALIDA = 'PO.008', // src/store/proposta/dadosBancarios/sagas/postInfoValidaSaga.ts
  P0_009_LEILAO_INSS = 'PO.009', // src/store/proposta/leilaoInss/sagas/getPropostaLeilaoInss.ts

  // Assinatura
  A0_001_ENVIAR_ASSINATURA = 'A0_001', // src/store/assinatura/documentosAssinatura/sagas/sendDocumentosAssinatura.ts

  // EXERCITO
  E0_001_EXERCITO_AVERBACAO = 'E0_001', // src/store/assinatura/averbacaoExercito/sagas/postValidaSenhaExercito.ts
  A0_002_ENVIAR_ASSINCRONO = 'A0_002', // src/store/assinatura/documentosAssinatura/sagas/postDocumentoAssincrono.ts

  // SIAPE
  S0_001_SIAPE_AVERBACAO = 'S0_001', // src/store/assinatura/dadosToken/sagas/postRealizarAverbacaoSiapeSaga.ts
}

export enum TipoMiniatura {
  RG_FRENTE = 'rg-frente',
  RG_VERSO = 'rg-verso',
  CNH_FRENTE = 'cnh-frente',
  CNH_VERSO = 'cnh-verso',
  RNE_FRENTE = 'rne-frente',
  RNE_VERSO = 'rne-verso',
}

export enum FontesPagamento {
  INSS = '6',
}

export enum StepsEnum {
  INICIO_CAPTURA = 'inicio-captura-dados',
  NATURALIDADE = 'envio-dados-naturalidade',
  DADOS_PESSOAIS = 'envio-dados-mae-sexo',
  DADOS_ADICIONAIS = 'envio-dados-escolaridade-patrimonio',
  ENDERECO = 'envio-dados-endereco',
  DADOS_BANCARIOS = 'envio-dados-bancarios',
  INICIO_CAPTURA_DOCUMENTOS = 'inicio-captura-documentos',
  SELECIONADO_ENVIO_DOCUMENTOS_PESSOAIS = 'selecionado-envio-documentos-pessoais',
  SELECIONADO_ENVIO_VIDEO_SELFIE = 'selecionado-envio-video-selfie',
  SELECIONADO_ENVIO_CONTRACHEQUE = 'selecionado-envio-contracheque',
  FOTOGRAFAR_DOCUMENTO = 'fotografar-documento',
  FOTOGRAFAR_DOCUMENTO_CONCLUIDO = 'fotografar-documento-concluido',
  ANEXAR_DOCUMENTO = 'anexar-documento',
  ANEXO_DE_DOCUMENTOS_CONCLUIDO = 'anexar-documentos-concluido',
  DELETE_ANEXO_DOCUMENTO = 'delete-anexo-documento',
  INICIO_VIDEO_SELFIE = 'inicio-biometria',
  ORIENTACOES_VIDEO_SELFIE = 'orientacoes-gravar-biometria',
  GRAVAR_VIDEO_SELFIE = 'gravar-biometria',
  FINALIZAR_VIDEO_SELFIE = 'finalizar-biometria',
  CONCLUSAO_ENVIO_DOCUMENTOS = 'conclusao-envio-biometria',
  CONTINUAR_DOCUMENTOS_ASSINADO = 'continuar-documentos-assinados',
  REENVIAR_TOKEN_TELEFONE = 'reenviar-token-telefone',
  CONFIRMAR_TOKEN_TELEFONE = 'confirmar-token-telefone',
  REENVIAR_TOKEN_EMAIL = 'reenviar-token-email',
  CONFIRMAR_TOKEN_EMAIL = 'confirmar-token-email',
  REVISAO_ASSINATURA = 'revisao-assinatura',
  PROPOSTA_FINALIZADA = 'proposta-finalizada',
  ENVIO_EXTRATO_BANCARIO = 'envio-extrato-bancario',
  LEILAO_PRIVADO = `leilao-privado`,
  CCB = 'ccb',
  DECLARACAO_RESIDENCIA = 'declaracao-residencia',
  AUTORIZACAO_CONSULTA_MARGEM = 'autorizacao-consulta-margem',
  SEGURO_PRESTAMISTA = 'seguro-prestamista',
  SEGURO_PRESTAMISTA_INSS = 'seguro-prestamista-inss',
  CCB_EXERCITO = 'ccb-exercito',
  PUBLICO_VULNERAVEL = 'publico-vulneravel',
  ADF = 'adf',
  TERMO_PORTABILIDADE = 'termo-portabilidade',
  TERMO_REFINANCIAMENTO = 'termo-refinanciamento',
  CCB_CAPA = 'ccb-capa',
}

export enum ConsignanteApi {
  SEF = 'SEPLAG',
  CBMMG = 'Bombeiros',
  IPSEMG = 'IPSEMG',
  PMMG = 'PMMG',
  IPSM = 'IPSM',
  DPMG = 'Defensoria Publica MG',
}

export enum ConsignantePdf {
  SEF = 'SEF',
  CBMMG = 'CBMMG',
  IPSEMG = 'IPSEMG',
  PMMG = 'PMMG',
  IPSM = 'IPSM',
  DPMG = 'DPMG',
}

export enum Consignado {
  SERVIDOR = 'servidor',
  BOLSISTA = 'bolsista',
  PENSIONISTA = 'pensionista',
  CONTRATADO = 'contratado',
}

export enum ConveniosCodes {
  ESTADO_MG = 1,
  TJMG = 2,
  ESTADO_RJ = 4,
  ESTADO_SP = 10,
  ESTADO_BAHIA = 12,
  ESTADO_MS = 14,
  ESTADO_GO = 20,
  MIN_PUB_MG = 43,
  TRE_MG = 45,
  METRO = 46,
  TRE_SP = 55,
  STJ = 56,
  TCE_MG = 62,
  TRT = 75,
  ESTADO_MT = 105,
  MARINHA_BR = 13,
  FORCA_AEREA_BR = 23,
  EXERCITO_BR = 37,
  PREF_BH = 5,
  PREF_SALVADOR = 16,
  PREF_SP = 17,
  PREF_CONTAGEM = 34,
  HOSPITAL_ODILON = 42,
  INSS = 6,
  SIAPE_SERVIDOR_FEDERAL = 3,
}

export enum CCBEnum {
  CCBEXERCITO = 'exercito',
  CCB = 'ccb',
}

export enum PaginaEnum {
  PAGINA_UM = 1,
  PAGINA_DOIS = 2,
  PAGINA_TRES = 3,
  PAGINA_QUATRO = 4,
  PAGINA_CINCO = 5,
  PAGINA_SEIS = 6,
  PAGINA_SETE = 7,
  PAGINA_OITO = 8,
}

export const Convenios = [
  {
    id: 1,
    descricao: 'ESTADO_MG',
  },
  {
    id: 2,
    descricao: 'TJMG',
  },
  {
    id: 3,
    descricao: 'SIAPE_SERVIDOR_FEDERAL',
  },
  {
    id: 4,
    descricao: 'ESTADO_RJ',
  },
  {
    id: 5,
    descricao: 'PREF_BH',
  },
  {
    id: 6,
    descricao: 'INSS',
  },
  {
    id: 10,
    descricao: 'ESTADO_SP',
  },
  {
    id: 12,
    descricao: 'ESTADO_BAHIA',
  },
  {
    id: 13,
    descricao: 'MARINHA_BR',
  },
  {
    id: 14,
    descricao: 'ESTADO_MS',
  },
  {
    id: 16,
    descricao: 'PREF_SALVADOR',
  },
  {
    id: 17,
    descricao: 'PREF_SP',
  },
  {
    id: 20,
    descricao: 'ESTADO_GO',
  },
  {
    id: 23,
    descricao: 'FORCA_AEREA_BR',
  },
  {
    id: 34,
    descricao: 'PREF_CONTAGEM',
  },
  {
    id: 37,
    descricao: 'EXERCITO_BR',
  },
  {
    id: 42,
    descricao: 'HOSPITAL_ODILON',
  },
  {
    id: 43,
    descricao: 'MIN_PUB_MG',
  },
  {
    id: 45,
    descricao: 'TRE_MG',
  },
  {
    id: 46,
    descricao: 'METRO',
  },
  {
    id: 55,
    descricao: 'TRE_SP',
  },
  {
    id: 56,
    descricao: 'STJ',
  },
  {
    id: 62,
    descricao: 'TCE_MG',
  },
  {
    id: 75,
    descricao: 'TRT',
  },
  {
    id: 105,
    descricao: 'ESTADO_MT',
  },
  {
    id: 117,
    descricao: 'PREF_VITORIA',
  },
  {
    id: 129,
    descricao: 'PREF_BETIM',
  },
  {
    id: 130,
    descricao: 'PREF_GOIANIA',
  },
  {
    id: 132,
    descricao: 'PREF_CAMPO_GRANDE',
  },
  {
    id: 999,
    descricao: 'OUTROS',
  },
]
