import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import {
  setCounterDocumentosAssinar,
  setDocumentos,
  setIdIteracao,
} from '@store/assinatura/documentosAssinatura/actions'

import { hideLoading, postDocumentosAssinatura, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  actionCallbackError?: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
    block: boolean
  }
  type: string
}

export default function* postDocumentosAssinaturaSaga({
  noRemoveLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => postDocumentosAssinatura(),
    actionTitle: 'Tentar Novamente',
    block: true,
  },
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  try {
    let docs = []
    if (!appState.assinatura.documentosAssinatura.idIteracao) {
      yield put(showLoading())

      const { data: dataDocumentos } = yield services.postDocumentosAssinatura(
        {
          cpf: appState.simulacao.cpf,
          numeroProposta: String(appState.proposta.criacaoProposta.numeroProposta),
        },
        newSimulatorApi,
      )

      if (dataDocumentos) {
        const docsEnviados = dataDocumentos.enviados.map((doc: Record<string, unknown>) => ({
          ...doc,
          isDone: true,
        }))
        docs = docsEnviados.concat(dataDocumentos?.pendentes)
        yield put(setDocumentos(docs))
        yield put(setCounterDocumentosAssinar(docs?.length))
        yield put(setIdIteracao(dataDocumentos?.idInteracao))
      }
    }
    if (!noRemoveLoader) {
      yield put(hideLoading())
    }
    return true
  } catch (error) {
    yield put(hideLoading())
    yield put(showError(actionCallbackError))
    return false
  }
}
