import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import {
  buscarDocumentosSaga,
  carregarCapturaSaga,
  getDocumentosAssinaturaCTPSSaga,
  getOrgaos,
  postAtualizarPropostaSaga,
  postCapturaDocumentosSaga,
  postCaptureDocumentsCTPSSaga,
  postDocumentosAssinaturaSaga,
} from '@store/captura/documentos/sagas'
import { DocumentosAssinaturaTypes } from '@store/assinatura/documentosAssinatura/types'

import { DocumentosTypes } from './documentos/types'
import { FotoDocumentosTypes } from './fotoDocumentos/types'
import sendToS3Saga from './fotoDocumentos/sagas/sendToS3'
import sendToS3PaySlipSaga from './contrachequeDocumentos/sagas/sendToS3PaySlip'
import { VideoSelfieTypes } from './videoSelfie/types'
import postFotoBiometriaLivenessSaga from './videoSelfie/sagas/postFotoBiometriaLivenessSaga'
import { postFotoDocumentoLivenessSaga } from './fotoDocumentos/sagas'
import { getBiometriaDataSaga, postValidateBiometricsSaga } from './videoSelfie/sagas'
import { ContrachequeDocumentosTypes } from './contrachequeDocumentos/types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(DocumentosTypes.ORGAO_EXPEDIDOR_REQUEST, getOrgaos)
  yield* takeLatest(FotoDocumentosTypes.SEND_TO_S3_SAGA, sendToS3Saga)
  yield* takeLatest(ContrachequeDocumentosTypes.SEND_TO_S3_PAYSLIP_SAGA, sendToS3PaySlipSaga)
  yield* takeLatest(VideoSelfieTypes.POST_FOTO_BIOMETRIA_LIVENESS, postFotoBiometriaLivenessSaga)
  yield* takeLatest(DocumentosTypes.CAPTURA_DOCUMENTOS_REQUEST, postCapturaDocumentosSaga)
  yield* takeLatest(DocumentosTypes.CARREGAR_CAPTURA_REQUEST, carregarCapturaSaga)
  yield* takeLatest(DocumentosTypes.DOCUMENTOS_ASSINATURA_REQUEST, postDocumentosAssinaturaSaga)
  yield* takeLatest(DocumentosAssinaturaTypes.ATUALIZAR_PROPOSTA, postAtualizarPropostaSaga)
  yield* takeLatest(DocumentosTypes.BUSCAR_DOCUMENTOS_SAGA, buscarDocumentosSaga)
  yield* takeLatest(DocumentosTypes.BUSCAR_DOCUMENTOS_CTPS_SAGA, postCaptureDocumentsCTPSSaga)
  yield* takeLatest(DocumentosTypes.BUSCAR_TERMOS_CTPS_SAGA, getDocumentosAssinaturaCTPSSaga)
  yield* takeLatest(
    FotoDocumentosTypes.POST_FOTO_DOCUMENTOS_LIVENESS,
    postFotoDocumentoLivenessSaga,
  )
  yield* takeLatest(VideoSelfieTypes.GET_BIOMETRIA_DADOS, getBiometriaDataSaga)
  yield* takeLatest(VideoSelfieTypes.POST_VALIDATE_BIOMETRICS, postValidateBiometricsSaga)
}
