import { BaseServices, HttpMethod } from '@services/base'

export interface DadosBancariosLeilaoInssType {
  bankCode: number
  agency: string
  currentAccount: string
  currentAccountDigit: string
}

export default async (
  tokenApi: string,
  data: DadosBancariosLeilaoInssType,
  auctionId: string,
  newSimulatorApi: boolean,
) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_POST_DADOS_BANCARIOS_LEILAO_INSS
    : process.env.REACT_APP_POST_DADOS_BANCARIOS_LEILAO_INSS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
    headers: {
      authorization: `Bearer ${tokenApi}`,
      auctionId,
    },
  })

  return response
}
