import { Reducer } from 'redux'

import { LeilaoInssState, LeilaoInssTypes } from './types'

const INITIAL_STATE: LeilaoInssState = {
  loading: false,
  error: false,
  loadingMargin: false,
  errorMargin: false,
  leilaoInss: {
    loanType: '',
    transactionId: '',
    solicitationId: 0,
    processingStatus: '',
    processingStep: '',
    socialNumber: '',
    benefitNumber: '',
    requestExpirationTime: '',
    birthDate: '',
    beneficiaryName: '',
    hasJudicialConcession: false,
    hasLegalRepresentative: false,
    hasAttorney: false,
    hasEntityRepresentation: false,
    hasEligibleForLoan: false,
    hasBlockedForLoan: false,
    benefitDispatchDate: '',
    numberOfInstallments: 0,
    releasedValue: 0,
    availableMargin: 0,
    committedValue: 0,
    maximumValueForCommitment: 0,
    alimony: {
      code: 0,
      description: '',
    },
    benefitType: {
      code: 0,
      description: '',
    },
    benefitStatus: {
      code: 0,
      description: '',
    },
    personExposedPolitically: {
      code: 0,
      description: '',
    },
    createdAt: '',
    updatedAt: '',
    benefitTerminationDate: '',
    legalRepresentativeSocialNumber: '',
    legalRepresentativeName: '',
    legalRepresentativeTerminationDate: '',
    quotaExtinctionDate: '',
    ufAPS: '',
    proposalCode: '',
    quantityOfReprocessing: 0,
    simulationId: '',
    ratingPrecification: '',
    code: '',
    hashOperation: '',
    address: {
      proposalCode: 0,
      residenceTime: 0,
      residenceType: 0,
      correspondenceAddressType: 0,
      zipcode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      oof: '',
    },
  },
}

const reducer: Reducer<LeilaoInssState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LeilaoInssTypes.SET_DADOS_LEILAO_REQUEST:
    case LeilaoInssTypes.SET_ATUALIZAR_ENDERECO_LEILAO_INSS:
      return { ...state, loading: true }
    case LeilaoInssTypes.SET_DADOS_LEILAO_SUCCESS:
      return {
        ...state,
        leilaoInss: action.payload,
        loading: false,
        error: false,
      }
    case LeilaoInssTypes.SET_ATUALIZAR_ENDERECO_LEILAO_INSS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case LeilaoInssTypes.SET_DADOS_LEILAO_ERROR:
      return { ...state, loading: false, error: true }
    case LeilaoInssTypes.SET_MARGIN_QUERY_REQUEST:
      return { ...state, loadingMargin: true }
    case LeilaoInssTypes.SET_MARGIN_QUERY_SUCCESS:
      return {
        ...state,
        loadingMargin: false,
        errorMargin: false,
      }
    case LeilaoInssTypes.SET_MARGIN_QUERY_ERROR:
      return { ...state, loadingMargin: false, errorMargin: true }
    case LeilaoInssTypes.SET_TRANSACTION_ID:
      return {
        ...state,
        leilaoInss: {
          ...state.leilaoInss,
          transactionId: action.payload,
        },
      }
    case LeilaoInssTypes.RESET:
      return { ...INITIAL_STATE }

    case LeilaoInssTypes.SET_LOADING_LEILAO:
      return { ...state, loading: true }
    case LeilaoInssTypes.SET_LOADING_LEILAO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case LeilaoInssTypes.SET_LOADING_LEILAO_ERROR:
      return { ...state, loading: false, error: true }

    default:
      return state
  }
}

export default reducer
