import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import {
  setCounterDocumentosAssinar,
  setDocumentos,
  setIdIteracao,
} from '@store/assinatura/documentosAssinatura/actions'
import { ErroType } from '@store/proposta/leilaoInss/types'
import { HttpStatus } from '@services/base'

import { postDocumentosAssinatura } from '../actions'
import { BuscarPayloadCTPS } from '../types'

export default function* getDocumentosAssinaturaCTPSSaga(action: AnyAction) {
  const payload = action.payload as BuscarPayloadCTPS
  const appState = yield* select((state: ApplicationState) => state)
  const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
  try {
    let docs = []
    if (!appState.assinatura.documentosAssinatura.idIteracao) {
      const { data: dataDocumentos } = yield services.getDocumentosAssinatura(
        String(tokenApi),
        String(appState.proposta.criacaoProposta.numeroProposta),
      )

      if (dataDocumentos) {
        const docsEnviados = dataDocumentos.enviados.map((doc: Record<string, unknown>) => ({
          ...doc,
          isDone: true,
        }))
        docs = docsEnviados.concat(dataDocumentos?.pendentes)
        yield put(setDocumentos(docs))
        yield put(setCounterDocumentosAssinar(docs?.length))
        yield put(setIdIteracao(dataDocumentos?.idInteracao))
      }
    }
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      payload.callback(true)
    } else {
      yield put(
        showError({
          message: 'Aguarde um instante e tente novamente.',
          actionCallback: () => postDocumentosAssinatura(),
          actionTitle: 'Tentar Novamente',
          block: true,
        }),
      )
    }
  }
}
