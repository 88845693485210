/**
 * Actions Types
 */

import { HttpStatus } from '@services/base'
import { Anexo } from '@store/captura/contrachequeDocumentos/types'

export enum LeilaoInssTypes {
  SET_DADOS_LEILAO_REQUEST = '@simulacao/leilaoInss/SET_DADOS_LEILAO_REQUEST',
  SET_DADOS_LEILAO_SUCCESS = '@simulacao/leilaoInss/SET_DADOS_LEILAO_SUCCESS',
  SET_DADOS_LEILAO_ERROR = '@simulacao/leilaoInss/SET_DADOS_LEILAO_ERROR',
  SET_MARGIN_QUERY_REQUEST = '@simulacao/leilaoInss/SET_MARGIN_QUERY_REQUEST',
  SET_MARGIN_QUERY_SUCCESS = '@simulacao/leilaoInss/SET_MARGIN_QUERY_SUCCESS',
  SET_MARGIN_QUERY_ERROR = '@simulacao/leilaoInss/SET_MARGIN_QUERY_ERROR',
  SET_ATUALIZAR_ENDERECO_LEILAO_INSS = '@simulacao/leilaoInss/SET_ATUALIZAR_ENDERECO_LEILAO_INSS',
  SET_ATUALIZAR_ENDERECO_LEILAO_INSS_SUCCESS = '@simulacao/leilaoInss/SET_ATUALIZAR_ENDERECO_LEILAO_INSS_SUCCESS',
  SET_ATUALIZAR_ENDERECO_LEILAO_INSS_ERROR = '@simulacao/leilaoInss/SET_ATUALIZAR_ENDERECO_LEILAO_INSS_ERROR',
  SET_ATUALIZAR_DADOS_BANCARIOS_LEILAO_INSS = '@simulacao/leilaoInss/SET_ATUALIZAR_DADOS_BANCARIOS_LEILAO_INSS',
  SEND_TO_S3_LEILAO_INSS = '@simulacao/leilaoInss/SEND_TO_S3_LEILAO_INSS',
  GET_LIVENESS_LAST = '@simulacao/leilaoInss/GET_LIVENESS_LAST',
  POST_FINALIZAR_PENDENCIA_LEILAO_INSS = '@simulacao/leilaoInss/POST_FINALIZAR_PENDENCIA_LEILAO_INSS',
  SET_TRANSACTION_ID = '@simulacao/leilaoInss/SET_TRANSACTION_ID',
  RESET = 'RESET',
  SET_LOADING_LEILAO = '@simulacao/leilaoInss/SET_LOADING_LEILAO',
  SET_LOADING_LEILAO_SUCCESS = '@simulacao/leilaoInss/SET_LOADING_LEILAO_SUCCESS',
  SET_LOADING_LEILAO_ERROR = '@simulacao/leilaoInss/SET_LOADING_LEILAO_ERROR',
}

/**
 * State Type
 */

export interface S3Type {
  tipo: string
  anexos: Anexo[]
  callback?: () => void
}

interface Alimony {
  readonly code: number
  readonly description: string
}

interface BenefitType {
  readonly code: number
  readonly description: string
}

interface BenefitStatus {
  readonly code: number
  readonly description: string
}

interface PersonExposedPolitically {
  readonly code: number
  readonly description: string
}

interface Address {
  readonly proposalCode: number
  readonly residenceTime: number
  readonly residenceType: number
  readonly correspondenceAddressType: number
  readonly zipcode: string
  readonly street: string
  readonly number: number
  readonly complement: string
  readonly district: string
  readonly city: string
  readonly oof: string
}

export interface LeilaoInss {
  readonly loanType: string
  readonly transactionId: string
  readonly solicitationId: number
  readonly processingStatus: string
  readonly processingStep: string
  readonly socialNumber: string
  readonly benefitNumber: string
  readonly requestExpirationTime: string
  readonly birthDate: string
  readonly beneficiaryName: string
  readonly hasJudicialConcession: boolean
  readonly hasLegalRepresentative: boolean
  readonly hasAttorney: boolean
  readonly hasEntityRepresentation: boolean
  readonly hasEligibleForLoan: boolean
  readonly hasBlockedForLoan: boolean
  readonly benefitDispatchDate: string
  readonly numberOfInstallments: number
  readonly releasedValue: number
  readonly availableMargin: number
  readonly committedValue: number
  readonly maximumValueForCommitment: number
  readonly alimony: Alimony
  readonly benefitType: BenefitType
  readonly benefitStatus: BenefitStatus
  readonly personExposedPolitically: PersonExposedPolitically
  readonly createdAt: string
  readonly updatedAt: string
  readonly benefitTerminationDate: string
  readonly legalRepresentativeSocialNumber: string
  readonly legalRepresentativeName: string
  readonly legalRepresentativeTerminationDate: string
  readonly quotaExtinctionDate: string
  readonly ufAPS: string
  readonly proposalCode: string
  readonly quantityOfReprocessing: number
  readonly simulationId: string
  readonly ratingPrecification: string
  readonly code: string
  readonly hashOperation: string
  readonly address: Address
}

export interface LeilaoInssState {
  readonly loading: boolean
  readonly error: boolean
  readonly loadingMargin: boolean
  readonly errorMargin: boolean
  readonly leilaoInss: LeilaoInss
}

export interface PropostaLeilaoInssPayload {
  callback: (tokenExpirado?: boolean) => void
}

export interface ValidaLivenessLeilaoInssPayload {
  callback: (status: string) => void
}

export interface AtualizarEnderecoLeilaoInssPayload {
  callback: (naoLiberado: boolean, tokenExpirado?: boolean) => void
}

export interface AtualizarDadosBancariosLeilaoInssPayload {
  callback: (tokenExpirado?: boolean) => void
}

interface BankInformation {
  bank: string
  agency: string
  account: string
  countDigit: string
  creditType: string
}

export interface MarginQueryInss {
  bankInformation: BankInformation
}

export interface LivenessLast {
  unicoId: string
  proposta: string
  status: string
  etapa: string
  dataCriacao: string
  score: number
  faceMatch: true
  serpro: number
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}
