import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'

import {
  postFinalizarPendenciaLeilaoInss,
  setLoadingLeilao,
  setLoadingLeilaoError,
  setLoadingLeilaoSucess,
} from '../actions'
import { ErroType, PropostaLeilaoInssPayload } from '../types'

export default function* postFinalizarPendenciaLeilaoInssSaga(action: AnyAction) {
  const payload = action.payload as PropostaLeilaoInssPayload
  try {
    const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const { numeroProposta } = yield* select((s: ApplicationState) => s.proposta.criacaoProposta)
    const { transactionId } = yield* select(
      (s: ApplicationState) => s.proposta.leilaoInss.leilaoInss,
    )

    yield put(setLoadingLeilao())
    yield* call(
      services.postFinalizarPendenciaLeilaoInss,
      String(tokenApi),
      transactionId,
      String(numeroProposta),
      newSimulatorApi,
    )
    yield put(setLoadingLeilaoSucess())
    yield payload.callback()
  } catch (error) {
    yield put(setLoadingLeilaoError())
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      payload.callback(true)
    } else {
      yield put(
        showError({
          title: 'Erro ao finalizar pendencia do Leilão INSS',
          message: 'Ocorreu um erro ao finalizar pendencia do Leilão INSS.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.P0_009_LEILAO_INSS,
          actionCallback: () => postFinalizarPendenciaLeilaoInss(payload),
        }),
      )
    }
  }
}
