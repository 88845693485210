import { AnyAction } from 'redux'
import { call, put, select } from 'typed-redux-saga'

import postAtualizarEnderecoLeilaoInss from '@services/postAtualizarEnderecoLeilaoInss'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'

import { AtualizarEnderecoLeilaoInssPayload, ErroType } from '../types'
import {
  atualizarEnderecoLeilaoInss,
  atualizarEnderecoLeilaoInssSuccess,
  atualizarEnderecoLeilaoInssError,
} from '../actions'

export default function* postAtualizarEnderecoLeilaoInssSaga(action: AnyAction) {
  const payload = action?.payload as AtualizarEnderecoLeilaoInssPayload

  const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  const { transactionId } = yield* select((s: ApplicationState) => s.proposta.leilaoInss.leilaoInss)
  const { cep, logradouro, numero, complemento, bairro, cidadeSelecionada, estadoSelecionado } =
    yield* select((state: ApplicationState) => state.proposta.endereco)

  try {
    yield* call(
      postAtualizarEnderecoLeilaoInss,
      String(tokenApi),
      {
        zipcode: cep,
        street: logradouro,
        number: Number(numero),
        complement: complemento,
        district: bairro,
        city: cidadeSelecionada,
        oof: estadoSelecionado,
        residenceTime: 120,
        residenceType: 1,
        correspondenceAddressType: 1,
      },
      transactionId,
      newSimulatorApi,
    )

    yield put(atualizarEnderecoLeilaoInssSuccess())
    payload.callback(false)
  } catch (error) {
    yield put(atualizarEnderecoLeilaoInssError())
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      payload.callback(true)
    } else if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      payload.callback(true, true)
    } else {
      yield put(
        showError({
          title: 'Erro ao atualizar endereço do Leilão INSS',
          message: 'Ocorreu um erro ao atualizar endereço do Leilão INSS.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => atualizarEnderecoLeilaoInss(payload),
        }),
      )
    }
  }
}
