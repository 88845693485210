import { AnyAction } from 'redux'
import { call, put, select } from 'typed-redux-saga'

import postAtualizarDadosBancariosLeilaoInss from '@services/postAtualizarDadosBancariosLeilaoInss'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { HttpStatus } from '@services/base'

import { AtualizarDadosBancariosLeilaoInssPayload, ErroType } from '../types'
import { atualizarDadosBancariosLeilaoInss } from '../actions'

export default function* postAtualizarDadosBancariosLeilaoInssSaga(action: AnyAction) {
  const payload = action?.payload as AtualizarDadosBancariosLeilaoInssPayload

  const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  const { transactionId } = yield* select((s: ApplicationState) => s.proposta.leilaoInss.leilaoInss)
  const { bancoSelecionado, agencia, conta, digito } = yield* select(
    (state: ApplicationState) => state.proposta.dadosBancarios,
  )

  try {
    yield* call(
      postAtualizarDadosBancariosLeilaoInss,
      String(tokenApi),
      {
        bankCode: Number(bancoSelecionado),
        agency: agencia,
        currentAccount: conta,
        currentAccountDigit: digito,
      },
      transactionId,
      newSimulatorApi,
    )

    payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      payload.callback(true)
    } else {
      yield put(
        showError({
          title: 'Erro ao atualizar dados bancários do Leilão INSS',
          message: 'Ocorreu um erro ao atualizar dados bancários do Leilão INSS.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => atualizarDadosBancariosLeilaoInss(payload),
        }),
      )
    }
  }
}
