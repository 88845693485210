import { BaseServices, HttpMethod } from '@services/base'
import { LeilaoInss } from '@store/proposta/leilaoInss/types'

export default (
  tokenApi: string,
  socialDocument: string,
  proposalNumber: string,
  transactionId: string,
  newSimulatorApi: boolean,
) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_GET_SIMULACAO_LEILAO_INSS
    : process.env.REACT_APP_GET_SIMULACAO_LEILAO_INSS

  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST

  let headersCustom = {}

  if (socialDocument) headersCustom = { ...headersCustom, socialDocument }
  if (proposalNumber) headersCustom = { ...headersCustom, proposalNumber }
  if (transactionId) headersCustom = { ...headersCustom, transactionId }

  const response = BaseServices.request<LeilaoInss>({
    pathname,
    method,
    host,
    headers: {
      authorization: `Bearer ${tokenApi}`,
      ...headersCustom,
    },
  })

  return response
}
