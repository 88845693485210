import { action, Action } from 'typesafe-actions'

import {
  AtualizarDadosBancariosLeilaoInssPayload,
  AtualizarEnderecoLeilaoInssPayload,
  LeilaoInss,
  LeilaoInssTypes,
  PropostaLeilaoInssPayload,
  S3Type,
  ValidaLivenessLeilaoInssPayload,
} from './types'

export const buscarPropostaLeilao = (payload: PropostaLeilaoInssPayload): Action =>
  action(LeilaoInssTypes.SET_DADOS_LEILAO_REQUEST, payload)

export const buscarPropostaLeilaoSuccess = (payload: LeilaoInss): Action =>
  action(LeilaoInssTypes.SET_DADOS_LEILAO_SUCCESS, payload)

export const buscarPropostaLeilaoError = (): Action =>
  action(LeilaoInssTypes.SET_DADOS_LEILAO_ERROR)

export const postMarginQueryInss = (payload: PropostaLeilaoInssPayload): Action =>
  action(LeilaoInssTypes.SET_MARGIN_QUERY_REQUEST, payload)

export const postMarginQueryInssSuccess = (): Action =>
  action(LeilaoInssTypes.SET_MARGIN_QUERY_SUCCESS)

export const postMarginQueryInssError = (): Action => action(LeilaoInssTypes.SET_MARGIN_QUERY_ERROR)

export const clearStorage = (): Action => action(LeilaoInssTypes.RESET)

export const atualizarEnderecoLeilaoInss = (payload: AtualizarEnderecoLeilaoInssPayload): Action =>
  action(LeilaoInssTypes.SET_ATUALIZAR_ENDERECO_LEILAO_INSS, payload)

export const atualizarEnderecoLeilaoInssSuccess = (): Action =>
  action(LeilaoInssTypes.SET_ATUALIZAR_ENDERECO_LEILAO_INSS_SUCCESS)

export const atualizarEnderecoLeilaoInssError = (): Action =>
  action(LeilaoInssTypes.SET_ATUALIZAR_ENDERECO_LEILAO_INSS_SUCCESS)

export const atualizarDadosBancariosLeilaoInss = (
  payload: AtualizarDadosBancariosLeilaoInssPayload,
): Action => action(LeilaoInssTypes.SET_ATUALIZAR_DADOS_BANCARIOS_LEILAO_INSS, payload)

export const sendToS3LeilaoInss = (payload: S3Type): Action =>
  action(LeilaoInssTypes.SEND_TO_S3_LEILAO_INSS, payload)

export const getLivenessLast = (payload: ValidaLivenessLeilaoInssPayload): Action =>
  action(LeilaoInssTypes.GET_LIVENESS_LAST, payload)

export const postFinalizarPendenciaLeilaoInss = (payload: PropostaLeilaoInssPayload): Action =>
  action(LeilaoInssTypes.POST_FINALIZAR_PENDENCIA_LEILAO_INSS, payload)

export const setTransactionId = (payload: string): Action =>
  action(LeilaoInssTypes.SET_TRANSACTION_ID, payload)

export const setLoadingLeilao = (): Action => action(LeilaoInssTypes.SET_LOADING_LEILAO)
export const setLoadingLeilaoSucess = (): Action =>
  action(LeilaoInssTypes.SET_LOADING_LEILAO_SUCCESS)
export const setLoadingLeilaoError = (): Action => action(LeilaoInssTypes.SET_LOADING_LEILAO_ERROR)
