import { BaseServices, HttpMethod } from '@services/base'

export default async (
  tokenApi: string,
  data: FormData,
  socialDocument: string,
  newSimulatorApi: boolean,
) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_POST_UPLOAD_DOC_LEILAO_INSS
    : process.env.REACT_APP_POST_UPLOAD_DOC_LEILAO_INSS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
    headers: {
      authorization: `Bearer ${tokenApi}`,
      'Content-Type': 'multipart/form-data',
      socialDocument,
    },
  })

  return response
}
