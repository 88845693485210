import { BaseServices, HttpMethod } from '@services/base'

export interface EnderecoLeilaoInssType {
  zipcode: string
  street: string
  number: number
  complement: string
  district: string
  city: string
  oof: string
  residenceTime: number
  residenceType: number
  correspondenceAddressType: number
}

export default async (
  tokenApi: string,
  data: EnderecoLeilaoInssType,
  auctionId: string,
  newSimulatorApi: boolean,
) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_POST_ENDERECO_LEILAO_INSS
    : process.env.REACT_APP_POST_ENDERECO_LEILAO_INSS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
    headers: {
      authorization: `Bearer ${tokenApi}`,
      auctionId,
    },
  })

  return response
}
