import { BaseServices, HttpMethod } from '@services/base'
import { LivenessLast } from '@store/proposta/leilaoInss/types'

export default (proposta: string, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_BIOMETRIA_LAST_LIVENESS
    : process.env.REACT_APP_BIOMETRIA_LAST_LIVENESS

  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request<LivenessLast>({
    pathname,
    method,
    host,
    headers: {
      proposta,
    },
  })

  return response
}
