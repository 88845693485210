import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { showError } from '@store/ui/error/actions'
import services from '@services'
import { ApplicationState } from '@store/types'
import { setIdIteracao, setTipoDocumento } from '@store/proposta/criacaoProposta/actions'
import { ErroType } from '@store/proposta/leilaoInss/types'
import { HttpStatus } from '@services/base'

import { buscarDocumentosCTPS } from '../actions'
import { BuscarPayloadCTPS } from '../types'

export default function* postCaptureDocumentsCTPSSaga(action: AnyAction) {
  const payload = action.payload as BuscarPayloadCTPS
  try {
    const propostaInfo = yield* select((state: ApplicationState) => state.proposta.criacaoProposta)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    // Caso ja tenha carregado os documentos, não busca novamente para evitar erro de criação de integração com GEDIN
    if (!propostaInfo?.tipoDocumento) {
      const appState = yield* select((state: ApplicationState) => state)
      const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
      const { data } = yield services.postCaptureDocuments(
        {
          cpf: appState.simulacao.cpf,
          numeroProposta: String(appState.proposta.criacaoProposta.numeroProposta),
        },
        newSimulatorApi,
        true,
        tokenApi,
      )

      if (data) {
        const tipoDocumento = data.enviados.concat(data.pendentes)
        yield put(setTipoDocumento(tipoDocumento))
        yield put(setIdIteracao(data.idInteracao))
      }
    }
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      payload.callback(true)
    } else {
      yield put(
        showError({
          message: 'Aguarde um instante e tente novamente.',
          actionCallback: () => buscarDocumentosCTPS(),
          actionTitle: 'Tentar Novamente',
          block: true,
        }),
      )
    }
  }
}
