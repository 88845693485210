import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataType {
  cpf: string
  numeroProposta: string
}

export default async (
  data: DataType,
  newSimulatorApi: boolean,
  isPortalUrl?: boolean,
  tokenApi?: string,
) => {
  let pathname

  if (isPortalUrl) pathname = process.env.REACT_APP_CAPTURE_DOCUMENTS_PORTAL
  else
    pathname = newSimulatorApi
      ? process.env.REACT_APP_CAPTURE_DOCUMENTS_BFF
      : process.env.REACT_APP_CAPTURE_DOCUMENTS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const headers: Record<string, string> = {}

  if (BridgeService.isBrowser() && isPortalUrl) {
    headers.Authorization = `Bearer ${tokenApi}`
  }

  const response = await BaseServices.request<CapturaDocumentoRequest>({
    pathname,
    method,
    host,
    headers,
    data,
  })
  return response
}

export interface CapturaDocumentoRequest {
  data: {
    idInteracao: string
    numeroProposta: string
    pendentes: []
    enviados: []
  }
}
