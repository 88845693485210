import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { getLivenessLast } from '../actions'
import { ValidaLivenessLeilaoInssPayload } from '../types'

export default function* getLivenessLastSaga(action: AnyAction) {
  const payload = action.payload as ValidaLivenessLeilaoInssPayload
  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const { proposalCode } = yield* select(
      (s: ApplicationState) => s.proposta.leilaoInss.leilaoInss,
    )

    const response = yield* call(services.getLastLiveness, proposalCode, newSimulatorApi)

    yield payload.callback(response.data.status)
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao consultar a última biometria',
        message: 'Ocorreu um erro ao consultar a última biometria.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_009_LEILAO_INSS,
        actionCallback: () => getLivenessLast(payload),
      }),
    )
  }
}
