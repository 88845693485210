import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { Anexo } from '@store/captura/fotoDocumentos/types'
import { base64toBlob } from '@utils/functions'
import { setIsDone, setIsError, setIsLoading } from '@store/captura/fotoDocumentos/actions'
import {
  setIsDone as setIsDoneExtrato,
  setIsError as setIsErrorExtrato,
  setIsLoading as setIsLoadingExtrato,
} from '@store/captura/contrachequeDocumentos/actions'

interface Props {
  payload: {
    anexos: Anexo[]
    tipo: string
  }
  type: string
}

export default function* postUpdateDocLeilaoInssSaga({ payload }: Props) {
  const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  const { cpf } = yield* select((s: ApplicationState) => s.simulacao)

  try {
    const formDataDoc = new FormData()

    payload.anexos.forEach(({ anexo }) => {
      const blob = base64toBlob(anexo.blobs[0].data)
      const imageFile = new File([blob], 'image.png', { type: blob.type })

      formDataDoc.append('file', imageFile, anexo.nome)
    })
    formDataDoc.append('idTipoDocumento', payload.tipo)

    yield* call(
      services.postUploadDocLeilaoInss,
      String(tokenApi),
      formDataDoc,
      cpf,
      newSimulatorApi,
    )

    if (payload.tipo === 'RG_CNH') {
      yield put(setIsDone(true))
      yield put(setIsLoading(false))
    } else {
      yield put(setIsDoneExtrato(true))
      yield put(setIsLoadingExtrato(false))
    }
  } catch (error) {
    if (payload.tipo === 'RG_CNH') {
      yield put(setIsError(true))
      yield put(setIsLoading(false))
    } else {
      yield put(setIsErrorExtrato(true))
      yield put(setIsLoadingExtrato(false))
    }

    yield* put(
      showError({
        title: 'Erro ao enviar o documento',
        message: 'Por favor tente novamente.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.C0_002_S3ENVIO,
      }),
    )
  }
}
