import { BaseServices, HttpMethod } from '@services/base'

export default async (tokenApi: string, proposalCode: string) => {
  const pathname = `${process.env.REACT_APP_DOCUMENTOS_ASSINATURA_PORTAL}/${proposalCode}/signature`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {}
  headers.Authorization = `Bearer ${tokenApi}`

  const response = await BaseServices.request<DocumentoAssinaturaRequest>({
    pathname,
    method,
    host,
    headers,
  })
  return response
}
export interface DocumentoAssinaturaRequest {
  data: {
    idInteracao: string
    numeroProposta: string
    pendentes: []
    enviados: []
  }
}
