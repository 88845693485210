import { put, select } from 'typed-redux-saga'

import services from '@services'
import { ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'

import { deleteAnexo, setIsDone, setIsError, setIsLoading } from '../actions'
import { Anexo } from '../types'

interface Props {
  payload: {
    anexos: Anexo[]
    tipo: string
    callback?: () => void
  }
  type: string
}
export default function* sendToS3Saga({ payload }: Props) {
  const { cpf } = yield* select((s: ApplicationState) => s.simulacao)
  const tipoDocumentos = yield* select(
    (s: ApplicationState) => s.proposta.criacaoProposta.tipoDocumento,
  )
  const { newVersion } = yield* select((s: ApplicationState) => s.ui.navigation)
  const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
  const { loanType } = yield* select((s: ApplicationState) => s.proposta.leilaoInss.leilaoInss)
  const regex = /data:.*base64,/
  try {
    const files = payload.anexos.flatMap((e) =>
      e.anexo.blobs.map((blob) => ({
        file: blob.data.replace(regex, ''),
        extension: `.${e.anexo.nome.split('.').pop()}` || '',
      })),
    )

    yield services.postSendFileToS3(
      {
        files,
        type:
          tipoDocumentos?.filter((tipo) => tipo.idTipoDocumento === payload.tipo)[0]
            ?.idTipoDocumento || payload.tipo,
      },
      cpf,
      loanType === 'CTPS',
      tokenApi,
    )

    yield put(setIsDone(true))
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsError(true))
    yield put(setIsLoading(false))
    if (newVersion) {
      yield put(deleteAnexo(0))
      if (payload.callback) {
        yield payload.callback()
      }
    } else {
      yield* put(
        showError({
          title: 'Erro ao enviar o documento',
          message: 'Por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.C0_002_S3ENVIO,
        }),
      )
    }
  }
}
