import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface IBiometria {
  document: string
  name: string
  email: string
  celular: string
  proposalCode: string
  onlySelfie: boolean
  photo: string
  encryptedPhoto: string
  isFullCaptureFlow: boolean
}

export interface IResponseBiometria {
  unicoId: string
}
const pathByProduto = {
  PORTABILIDADE: String(process.env.REACT_APP_BIOMETRIA_LIVENESS_PORTABILITY_WEB),
  REFINANCIAMENTO: String(process.env.REACT_APP_BIOMETRIA_LIVENESS_REFIN_WEB),
  NOVO: String(process.env.REACT_APP_BIOMETRIA_LIVENESS),
}
const produtosModulo = ['PORTABILIDADE', 'REFINANCIAMENTO']

export default async (data: IBiometria, produtoOrigem: string, accessToken?: string) => {
  const pathname =
    BridgeService.isBrowser() && accessToken && produtosModulo.includes(produtoOrigem)
      ? pathByProduto[produtoOrigem as 'PORTABILIDADE' | 'REFINANCIAMENTO' | 'NOVO']
      : process.env.REACT_APP_BIOMETRIA_LIVENESS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {}

  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<IResponseBiometria>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
