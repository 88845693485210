import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataType {
  type: string
  files: Files[]
}

export interface Files {
  file: string
  extension: string
}

export default async (data: DataType, cpf: string, isPortalUrl?: boolean, accessToken?: string) => {
  const pathname = isPortalUrl
    ? process.env.REACT_APP_ENVIAR_ANEXO_PORTAL
    : process.env.REACT_APP_ENVIAR_ANEXO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser()) {
    headers.socialDocument = cpf

    if (isPortalUrl) headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<UploadLinkRequest>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface UploadLinkRequest {
  url: string
}
