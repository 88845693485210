/**
 * Actions Types
 */

export enum SimulacaoTypes {
  SET_ID_SIMULACAO = '@simulacao/SET_ID_SIMULACAO',
  SET_ID_CONVENIO = '@simulacao/SET_ID_CONVENIO',
  SET_CLIENT_ID = '@simulacao/SET_CLIENT_ID',
  SET_CPF = '@simulacao/SET_CPF',
  SET_SIMPLIFICADA_INSS = '@simulacao/SET_SIMPLIFICADA_INSS',
  SET_MATRICULA = '@simulacao/SET_MATRICULA',
  SET_VALOR_SIMULADO = '@simulacao/SET_VALOR_SIMULADO',
  SET_NAME = '@simulacao/SET_NAME',
  SET_EMAIL = '@simulacao/SET_EMAIL',
  SET_DATA_NASCIMENTO = '@simulacao/SET_DATA_NASCIMENTO',
  SET_TELEFONE = '@simulacao/SET_TELEFONE',
  SET_IS_LEILAO_INSS = '@simulacao/SET_IS_LEILAO_INSS',
  SET_IS_MAGNETICO_NAO_CC = '@simulacao/SET_IS_MAGNETICO_NAO_CC',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface SimulacaoState {
  readonly idSimulacao: string | number
  readonly idConvenio: string
  readonly clientId: string | number
  readonly cpf: string
  readonly simplificadaInss?: boolean
  readonly matricula?: string | number
  readonly valorSimulado: string
  readonly email: string
  readonly telefone: string
  readonly dataNascimento: string
  readonly nome: string
  readonly isLeilaoInss?: boolean
  readonly isMagneticoNaoCC?: boolean
}
