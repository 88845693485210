import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import {
  setAgencia,
  setBancoSelecionado,
  setConta,
  setDigito,
  setTipoConta,
} from '@store/proposta/dadosBancarios/actions'
import { HttpStatus } from '@services/base'
import { setIsMagneticoNaoCC } from '@store/simulacao/actions'

import {
  postMarginQueryInss,
  postMarginQueryInssSuccess,
  postMarginQueryInssError,
} from '../actions'
import { ErroType, PropostaLeilaoInssPayload } from '../types'

export default function* postMarginQueryInssSaga(action: AnyAction) {
  const payload = action.payload as PropostaLeilaoInssPayload
  try {
    const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const { transactionId, loanType } = yield* select(
      (s: ApplicationState) => s.proposta.leilaoInss.leilaoInss,
    )

    const response = yield* call(
      services.postMarginQueryInss,
      String(tokenApi),
      transactionId,
      newSimulatorApi,
      loanType,
    )

    if (response.data.bankInformation) {
      const { bank, account, agency, countDigit, creditType } = response.data.bankInformation
      yield put(setBancoSelecionado(bank))
      yield put(setConta(account))
      yield put(setAgencia(agency))
      yield put(setDigito(countDigit))
      yield put(setTipoConta(creditType === 'Conta Corrente' ? '1' : '2'))
    }

    yield put(postMarginQueryInssSuccess())

    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.NOT_FOUND) {
      yield put(setIsMagneticoNaoCC(true))
    } else if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield payload.callback(true)
    } else {
      yield put(postMarginQueryInssError())
      yield put(
        showError({
          title: 'Erro ao consultar margem para o Leilão INSS',
          message: 'Ocorreu um erro ao consultar margem para o Leilão INSS.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.P0_009_LEILAO_INSS,
          actionCallback: () => postMarginQueryInss(payload),
        }),
      )
    }
  }
}
