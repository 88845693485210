import { put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'
import { hideLoading, showLoading } from '@store/captura/documentos/actions'
import { showError } from '@store/ui/error/actions'

import { dadosResumoError, dadosResumoRequestCtps, dadosResumoSucess } from '../actions'

export default function* getResumoDadosSagaCTPS() {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    yield put(showLoading())
    const { data } = yield services.getDadosResumo(
      String(appState.proposta.criacaoProposta.numeroProposta),
      String(appState.globalApi.tokenApi),
    )

    yield put(dadosResumoSucess(data))
    yield put(hideLoading())
    return true
  } catch (error) {
    yield put(
      showError({
        message: 'Aguarde um instante e tente novamente.',
        actionCallback: () => dadosResumoRequestCtps(),
        actionTitle: 'Tentar Novamente',
        block: true,
      }),
    )
    yield put(hideLoading())
    yield put(dadosResumoError())
    return false
  }
}
