import { combineReducers } from 'redux'

import dadosBancarios from './dadosBancarios'
import { DadosBancariosState } from './dadosBancarios/types'
import criacaoProposta from './criacaoProposta'
import { CriacaoPropostaState } from './criacaoProposta/types'
import endereco from './endereco'
import { EnderecoState } from './endereco/types'
import escolaridade from './escolaridade'
import { EscolaridadeState } from './escolaridade/types'
import nacionalidade from './nacionalidade'
import { NacionalidadeState } from './nacionalidade/types'
import leilaoInss from './leilaoInss'
import { LeilaoInssState } from './leilaoInss/types'

export interface PropostaState {
  nacionalidade: NacionalidadeState
  escolaridade: EscolaridadeState
  endereco: EnderecoState
  dadosBancarios: DadosBancariosState
  criacaoProposta: CriacaoPropostaState
  leilaoInss: LeilaoInssState
}

export default combineReducers({
  nacionalidade,
  escolaridade,
  endereco,
  dadosBancarios,
  criacaoProposta,
  leilaoInss,
})
