import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import {
  setCep,
  setEstadoSelecionado,
  setLogradouro,
  setBairro,
  setCidadeSelecionada,
  setNumero,
  setComplemento,
} from '@store/proposta/endereco/actions'
import { setNumeroProposta } from '@store/proposta/criacaoProposta/actions'
import { setCpf } from '@store/simulacao/actions'
import { postDocumentosAssinaturaSaga } from '@store/captura/documentos/sagas'
import { HttpStatus } from '@services/base'

import {
  buscarPropostaLeilao,
  buscarPropostaLeilaoError,
  buscarPropostaLeilaoSuccess,
} from '../actions'
import { ErroType, PropostaLeilaoInssPayload } from '../types'

export default function* getPropostaLeilaoInss(action: AnyAction) {
  const payload = action.payload as PropostaLeilaoInssPayload
  try {
    const { tokenApi } = yield* select((s: ApplicationState) => s.globalApi)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const { cpf } = yield* select((s: ApplicationState) => s.simulacao)
    const { numeroProposta } = yield* select((s: ApplicationState) => s.proposta.criacaoProposta)
    const { transactionId } = yield* select(
      (s: ApplicationState) => s.proposta.leilaoInss.leilaoInss,
    )

    const response = yield* call(
      services.getPropostaLeilaoInss,
      String(tokenApi),
      cpf,
      String(numeroProposta),
      transactionId,
      newSimulatorApi,
    )
    yield put(buscarPropostaLeilaoSuccess(response.data))

    yield put(setNumeroProposta(response.data.proposalCode))
    if (response.data.socialNumber) {
      yield put(setCpf(response.data.socialNumber))
    }

    if (response.data.proposalCode && response.data.socialNumber) {
      yield* postDocumentosAssinaturaSaga({
        noRemoveLoader: true,
        type: '',
      })
    }

    if (response.data.address) {
      yield put(setCep(response.data.address.zipcode))
      yield put(setLogradouro(response.data.address.street))
      yield put(setEstadoSelecionado(response.data.address.oof))
      yield put(setCidadeSelecionada(response.data.address.city?.toUpperCase()))
      yield put(setBairro(response.data.address.district))
      yield put(setNumero(String(response.data.address.number)))
      yield put(setComplemento(String(response.data.address.complement)))
    }

    yield payload.callback()
  } catch (error) {
    yield put(buscarPropostaLeilaoError())
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.UNAUTHORIZED) {
      payload.callback(true)
    } else {
      yield put(
        showError({
          title: 'Erro ao buscar a proposta do Leilão INSS',
          message: 'Ocorreu um erro ao buscar a proposta do Leilão INSS.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.P0_009_LEILAO_INSS,
          actionCallback: () => buscarPropostaLeilao(payload),
        }),
      )
    }
  }
}
