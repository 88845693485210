import { action, Action } from 'typesafe-actions'

import { GlobalApiTypes, Estado, Cidade, PayloadAutorizacao } from './types'

export const estadosRequest = (): Action => action(GlobalApiTypes.ESTADOS_REQUEST)

export const estadosSuccess = (payload: Estado[] | null): Action =>
  action(GlobalApiTypes.ESTADOS_SUCCESS, payload)
export const estadosLoading = (): Action => action(GlobalApiTypes.ESTADOS_LOADING)
export const estadosError = (): Action => action(GlobalApiTypes.ESTADOS_ERROR)

export const cidadesRequest = (payload: string): Action =>
  action(GlobalApiTypes.CIDADES_REQUEST, payload)

export const cidadesSuccess = (payload: Cidade[] | null): Action =>
  action(GlobalApiTypes.CIDADES_SUCCESS, payload)
export const cidadesLoading = (): Action => action(GlobalApiTypes.CIDADES_LOADING)
export const cidadesError = (): Action => action(GlobalApiTypes.CIDADES_ERROR)

export const postIsafeAutorizacao = (payload: PayloadAutorizacao): Action =>
  action(GlobalApiTypes.POST_ISAFE_AUTORIZACAO, payload)

export const setTokenApi = (payload: string): Action =>
  action(GlobalApiTypes.SET_TOKEN_API, payload)

export const setUtmSource = (payload: string): Action =>
  action(GlobalApiTypes.SET_UTM_SOURCE, payload)
export const setUtmMedium = (payload: string): Action =>
  action(GlobalApiTypes.SET_UTM_MEDIUM, payload)
export const setUtmContent = (payload: string): Action =>
  action(GlobalApiTypes.SET_UTM_CONTENT, payload)
export const setUtmCampaign = (payload: string): Action =>
  action(GlobalApiTypes.SET_UTM_CAMPAIGN, payload)
export const setUtmTerm = (payload: string): Action => action(GlobalApiTypes.SET_UTM_TERM, payload)
