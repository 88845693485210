import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import {
  getBancos,
  getDadosBancariosInssSaga,
  postSalvarPropostaSaga,
  postInfoValidaSaga,
} from './dadosBancarios/sagas'
import { DadosBancariosTypes } from './dadosBancarios/types'
import { postCriarProposta, postTrackNewRelicSaga } from './criacaoProposta/sagas'
import { CriacaoPropostaTypes } from './criacaoProposta/types'
import { getEndereco, postRiscoEndereco } from './endereco/sagas'
import { EnderecoTypes } from './endereco/types'
import { getEscolaridades, getProfissoes } from './escolaridade/sagas'
import { EscolaridadeTypes } from './escolaridade/types'
import { LeilaoInssTypes } from './leilaoInss/types'
import {
  getLivenessLastSaga,
  getPropostaLeilaoInss,
  postAtualizarDadosBancariosLeilaoInssSaga,
  postAtualizarEnderecoLeilaoInssSaga,
  postMarginQueryInssSaga,
  postUpdateDocLeilaoInssSaga,
  postFinalizarPendenciaLeilaoInssSaga,
} from './leilaoInss/sagas'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(EscolaridadeTypes.ESCOLARIDADES_REQUEST, getEscolaridades)
  yield* takeLatest(EscolaridadeTypes.PROFISSOES_REQUEST, getProfissoes)
  yield* takeLatest(CriacaoPropostaTypes.CRIAR_PROPOSTA, postCriarProposta)
  yield* takeLatest(EnderecoTypes.ENDERECO_REQUEST, getEndereco)
  yield* takeLatest(DadosBancariosTypes.BANCOS_REQUEST, getBancos)
  yield* takeLatest(DadosBancariosTypes.SALVAR_PROPOSTA_REQUEST, postSalvarPropostaSaga)
  yield* takeLatest(DadosBancariosTypes.DADOS_BANCARIOS_INSS_REQUEST, getDadosBancariosInssSaga)
  yield* takeLatest(DadosBancariosTypes.INFO_VALIDA, postInfoValidaSaga)
  yield* takeLatest(CriacaoPropostaTypes.TRACK_NEW_RELIC, postTrackNewRelicSaga)
  yield* takeLatest(EnderecoTypes.RISCO_ENDERECO_REQUEST, postRiscoEndereco)
  yield* takeLatest(LeilaoInssTypes.SET_DADOS_LEILAO_REQUEST, getPropostaLeilaoInss)
  yield* takeLatest(LeilaoInssTypes.SEND_TO_S3_LEILAO_INSS, postUpdateDocLeilaoInssSaga)
  yield* takeLatest(LeilaoInssTypes.GET_LIVENESS_LAST, getLivenessLastSaga)
  yield* takeLatest(
    LeilaoInssTypes.POST_FINALIZAR_PENDENCIA_LEILAO_INSS,
    postFinalizarPendenciaLeilaoInssSaga,
  )
  yield* takeLatest(LeilaoInssTypes.SET_MARGIN_QUERY_REQUEST, postMarginQueryInssSaga)
  yield* takeLatest(
    LeilaoInssTypes.SET_ATUALIZAR_ENDERECO_LEILAO_INSS,
    postAtualizarEnderecoLeilaoInssSaga,
  )
  yield* takeLatest(
    LeilaoInssTypes.SET_ATUALIZAR_DADOS_BANCARIOS_LEILAO_INSS,
    postAtualizarDadosBancariosLeilaoInssSaga,
  )
}
